import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fieldset-toggler"
export default class extends Controller<HTMLInputElement> {
  connect() {
    $($(this.element).data('target')).attr('disabled', this.element.checked ? null : 'disabled').toggleClass('hidden', !this.element.checked);
    var self = this;
    $(this.element).on('change', function () {
      $($(self.element).data('target')).attr('disabled', this.checked ? null : 'disabled').toggleClass('hidden', !this.checked);
    })
  }
}
