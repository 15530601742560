
import { Controller } from "@hotwired/stimulus";
import { Turbo } from '@hotwired/turbo-rails';

class BaseControllerWithTurbo<T extends Element = Element> extends Controller<T> {
  protected fetchAndUpdate(url, data, method = 'GET') {
    let queryString = '';
    let body = undefined;
    if (method === 'GET') {
      queryString = new URLSearchParams(data).toString()
    } else {
      body = data
    }
    if (body instanceof FormData) {
      body.delete('_method')
    }
    fetch([url, queryString].filter(_ => !!_).join('?'), {
      method: method?.toUpperCase(),
      body: body,
      headers: {
        Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': this.getMetaContent('csrf-token') || undefined,
        'Cache-Control': 'no-cache'
      },
    })
      .then(response => response.ok ? response.text() : Promise.reject('Response not OK'))
      .then(html => Turbo.renderStreamMessage(html))
      .catch(error => console.error('Error:', error));
  }

  private getMetaContent(name) {
    return document.querySelector(`meta[name="${name}"]`)?.getAttribute('content');
  }
}

export default BaseControllerWithTurbo;
