import BaseControllerWithTurbo from "./base_controller_with_turbo";
import { FetchRequest } from '@rails/request.js'

// Connects to data-controller="support-form"
export default class extends BaseControllerWithTurbo<HTMLFormElement> {
  static targets = ['agencySelector', 'noAgencyCheckbox', 'consentToShare', 'referredCheckbox', 'saveButton', 'consentToShareCheckbox']

  declare readonly agencySelectorTargets: HTMLSelectElement[]
  declare readonly noAgencyCheckboxTargets: HTMLInputElement[]
  declare readonly consentToShareTargets: HTMLFieldSetElement[]
  declare readonly referredCheckboxTargets: HTMLInputElement[]
  declare readonly saveButtonTargets: HTMLInputElement[]
  declare readonly consentToShareCheckboxTargets: HTMLInputElement[]

  connect() {
    super.connect();
    console.debug("Support form controller connected");
  }
  consentToShareTargetConnected() {
    this.toggleConsentToShare();
  }
  consentToShareCheckboxTargetConnected() {
    this.toggleConsentConfirmation();
  }
  hide() {
    $(this.element).addClass('hidden');
  }
  show() {
    $(this.element).removeClass('hidden');
  }
  setAgency({ detail }) {
    this.show();

    this.updateWithAgency(detail.support_agency.id, '#new_agency_nested_form_alert');

  }
  referredCheckboxTargetConnected(target: Element) {
    this.toggleConsentToShare();
    const self = this;
    $(target).on('change', function () {
      self.toggleConsentToShare();
    })
  }

  private toggleConsentToShare() {
    const show = $(this.referredCheckboxTargets).is(':checked');
    $(this.consentToShareTargets).attr('disabled', !show && 'disabled').toggleClass('hidden', !show);
    this.toggleConsentConfirmation();
  }

  toggleConsentConfirmation() {
    const show = $(this.referredCheckboxTargets).is(':checked');
    const accepted = !show || $(this.consentToShareCheckboxTargets).is(':checked');
    if (accepted) {
      $(this.saveButtonTargets).attr('data-confirm-accepted', accepted.toString());
    } else {
      $(this.saveButtonTargets).removeAttr('data-confirm-accepted');
    }
  }

  agencySelectorTargetConnected(target: Element) {
    var self = this;
    var interval = setInterval(function () {
      if ((window as any).__Select2Utils != null) {
        clearInterval(interval);
        $(target).select2({
          placeholder: this.placeholder || "Select",
        }).on('change', (e) => self.selectAgency(e));
      }
    }, 10)
  }

  async selectAgency(e) {
    var agency_id = e.target.value;
    this.updateWithAgency(agency_id);
  }

  private async updateWithAgency(agency_id, scroll_to = null) {
    if (agency_id) {
      const body = {
        support: { support_agency_id: agency_id }
      } as any
      if (scroll_to) {
        body.scroll_to = scroll_to;
      }
      $(this.noAgencyCheckboxTargets).attr('checked', null);
      $('#edit_advice_areas_updated').html('');
      const request = new FetchRequest(this.element.method || 'post',
        $(this.element).attr('action'),
        {
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Turbo-Frame': 'new_support_form'
          }
        }
      )
      await request.perform();
    }
  }

  async selectNoAgency(e) {
    var val = true;
    $(this.agencySelectorTargets).val('').trigger('change');
    if (!e.target.checked) {
      val = false;
    }
    $('#edit_advice_areas_updated').html('');
    const request = new FetchRequest(this.element.method || 'post',
      $(this.element).attr('action'),
      {
        body: JSON.stringify(
          { agency_id_not_known: val }
        ),
        headers: {
          'Content-Type': 'application/json',
          'Turbo-Frame': 'new_support_form'
        }
      }
    )
    await request.perform();
  }

  updateAdviceAreasForAgency() {
    const formData = new FormData(this.element);
    formData.delete('_method');
    formData.append('submit_action', 'updated_advice_area');
    const request = new FetchRequest(this.element.method || 'post',
      $(this.element).attr('action'),
      {
        body: formData,
        headers: {
          'Turbo-Frame': 'new_support_form'
        }
      }
    )
    request.perform();
  }
}
