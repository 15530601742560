if (!window.Element || !window.Element.prototype || !window.Element.prototype.hasAttribute) {
   (function (document) {
      var originalGetElementById = document.getElementById;
      var originalGetElementsByTagName = document.getElementsByTagName;

      // The HasAttribute function.
      function hasAttribute(attrName) {
         return typeof this[attrName] !== 'undefined';
      }

      // Add the HasAttribute to the element if is not present yet.
      function attachFunction(element) {
         if (element && !element.hasAttribute) {
            element.hasAttribute = hasAttribute;
         }
         return element;
      }

      // Proxy of the document.getElementById
      document.getElementById = function (elementId) {
         var element = originalGetElementById(elementId);
         return attachFunction(element);
      }

      // Proxy of the document.getElementsByTagName
      document.originalGetElementsByTagName = function (tagName) {
         var elements = originalGetElementsByTagName(tagName);
         for (var i = 0, len = elements.length; i < len; i++) {
            attachFunction(element[i]);
         }
         return elements;
      }
   }(document));
}


if (!window.HTMLCollection.prototype.hasAttribute) {
   window.HTMLCollection.prototype.hasAttribute = function (attr) {
      for (var i = 0; i < this.length; i++) {
         if (this.item(i).hasAttribute(attr)) {
            return true
         }
      }
      return false;
   }
   window.HTMLCollection.prototype.setAttribute = function (attr) {
      for (var i = 0; i < this.length; i++) {
         this.item(i).setAttribute(attr)
      }
      return false;
   }

   window.HTMLCollection.prototype.getAttribute = function (attr) {
      for (var i = 0; i < this.length; i++) {
         return this.item(i).getAttribute(attr)
      }
      return undefined;
   }
}

if (!NodeList.prototype.remove){
   NodeList.prototype.remove = function(){
      for (var i=0;i< this.length; i++){
         this.item(i).remove();
      }
   }
}
