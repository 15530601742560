import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'acceptButton', 'dismissButton']

  toggle(event) {
    const { target: { value } } = event

    if (value === 'accept') {
      this.toggleAcceptButton(true)
      this.toggleDismissButton(false)
    } else if (value === 'dismiss') {
      this.toggleAcceptButton(false)
      this.toggleDismissButton(true)
    }
  }

  // Private

  toggleAcceptButton(predicate) {
    if (this.hasAcceptButtonTarget) {
      this.acceptButtonTarget.classList.toggle('hidden', !predicate)

      if (predicate) {
        this.acceptButtonTarget.dataset.js = 'ok'
      } else {
        this.acceptButtonTarget.removeAttribute('data-js')
      }
    }
  }

  toggleDismissButton(predicate) {
    if (this.hasDismissButtonTarget) {
      this.dismissButtonTarget.classList.toggle('hidden', !predicate)
    }
  }
}


