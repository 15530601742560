import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { delay: Number }

  scroll(_event) {
    setTimeout(() => {
      this.performScroll()
    }, this.delay)
  }

  performScroll() {
    const params = new URLSearchParams(window.location.search);
    const identifier = params.get('scroll_identifier');

    const element = document.getElementById(identifier)
    
    if (element) {
      element.scrollIntoView({behavior: 'smooth'})
    }
  }

  get delay() {
    return this.delayValue || 0
  }
}
