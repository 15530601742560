import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="advice-areas-form"
export default class extends Controller {
  static targets = ['otherContent', 'otherCheckbox']
  declare readonly otherContentTargets: HTMLFieldSetElement[];

  connect() {
    console.debug("AdviceAreasForm is connected");
  }

  otherCheckboxTargetConnected(el) {
    const self = this;
    $(el).on("change",function(){
      $(self.otherContentTargets).toggleClass('hidden', !el.checked);
      $(self.otherContentTargets).attr('disabled', !el.checked &&'disabled');
    })
  }
}
