import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="class-togglable"
export default class extends Controller {
  static targets = ['expandable', 'toggler']

  connect() {
    const params = new URLSearchParams(window.location.search);
    const identifier = params.get('toggle_identifier');
    
    if (identifier && identifier === this.element.id) {
      if (this.hasExpandableTarget) {
        this.expandableTarget.classList.remove('hidden')
        
        this.togglerTargets.forEach(toggler => toggler.classList.toggle('hidden'))
      }
    }
  }

  toggle(event) {
    const { className, show, targets } = event.params;
    let destination = targets || this.element;
    if (show === 'true') {
      $(destination).addClass(className);
    } else if (show !== undefined) {
      $(destination).removeClass(className);
    }
    if (show === undefined) {
      $(destination).toggleClass(className);
    }
  }
}
