import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="textarea-autosize"
export default class extends Controller<HTMLTextAreaElement> {
  minSize: number = 0;

  connect() {
    var self = this
    this.fitControl()
    $(this.element).on('input',
      function () {
        self.fitControl()
      }
    )
    $(window).on('resize', function () {
      self.fitControl()
    })
  }
  private fitControl() {
    if (this.minSize <= 0) {
      this.minSize = this.element.clientHeight;
      if (!this.minSize) {
        this.minSize = this.element.scrollHeight - parseFloat($(this).css('paddingTop').replace('px', '')) * 1 - parseFloat($(this).css('paddingBottom').replace('px', '')) * 1; // actual text height
      }

      return;
    }
    const el = this.element;
    el.style.height = `${this.minSize}px`;
    el.style.height = (Math.max(this.minSize, el.scrollHeight)) + "px";
  }

}
