import { Controller } from "@hotwired/stimulus"

const dateOptions = ['maxDate', 'minDate']

const OPTIONS = {
  date: dateOptions,
}

function dasherize(value) {
  return value.replace(/([A-Z])/g, (_, char) => `-${char.toLowerCase()}`).replace(/[\s_]+/g, '-')
}

// Connects to data-controller="date-picker"
export default class extends Controller {
  initialize() {
    this.config = {}
  }

  connect() {
    this.initializeOptions()

    const val = this.element.value
    FormComponents.handleDatePickers(this.element, this.config)

    if (val) {
      $(this.element).datepicker("setDate", new Date(val));
    }
  }

  // Allows to pass some config values via data attributes 
  // e.g. data-date-picker-max-date="0" will set maxDate 
  // to today - see jQuery datepicker docs
  //
  initializeOptions() {
    Object.keys(OPTIONS).forEach((optionType) => {
      const optionsCamelCase = OPTIONS[optionType]
      optionsCamelCase.forEach((option) => {
        const dasherizedOption = dasherize(option)
        if (this.data.has(dasherizedOption)) {
          this.config[option] = this[`_${optionType}`](dasherizedOption)
        }
      })
    })
  }

  _date(option) {
    return this.data.get(option)
  }
}
