import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  click(_event) {
    const params = new URLSearchParams(window.location.search);
    const identifier = params.get('click_identifier');

    const element = document.getElementById(identifier)

    if (!element || element.offsetParent === null) {
      return
    }
    
    element.click()
  }
}
