require('get-root-node-polyfill/implement');
require('intersection-observer');
import 'element-closest-polyfill';
import 'event-target-polyfill';
import 'yet-another-abortcontroller-polyfill';
import 'whatwg-fetch';
import "child-replace-with-polyfill";
import './polyfills/Template';
import './polyfills/CustomEvent';
import './polyfills/Element';
import './polyfills/append_prepend_element';
import './polyfills/element_remove';
import './polyfills/querySelector';
import './polyfills/CSS';

import "@hotwired/turbo-rails";

import { dispatch } from './utils';

// Entry point for the build script in your package.json
import "./controllers";

class URL extends window.URL {
  constructor(url, base) {

    super(url, base || window.location.href);
  }
}
window.URL = URL;

Turbo.setFormMode("optin");
Turbo.session.drive = false;

addEventListener("turbo:before-stream-render", ((event) => {
  const fallbackToDefaultActions = event.detail.render

  event.detail.render = function (streamElement) {
    if (streamElement.action == "redirect") {
      window.location.href = streamElement.getAttribute('target')
    } else if (streamElement.action == "scroll_to") {
      const target = streamElement.getAttribute('target');
      setTimeout(function () {
        $(target)[0]?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);
    } else {
      fallbackToDefaultActions(streamElement)
    }
  }
}));

let confirmLink = undefined;

$(document).on('click', 'a[data-turbo-confirm]', function () {
  confirmLink = this;
  setTimeout(function () {
    confirmLink = undefined;
  }, 100)
});

Turbo.setConfirmMethod(async (message, formElement, submitter) => {
  let response = false;
  if ($(submitter).attr("data-confirm-accepted")) {
    response = true;
  } else {
    if (submitter === undefined) {
      submitter = document.activeElement;
    }
    if (!$(submitter).attr("data-turbo-confirm") && confirmLink) {
      submitter = confirmLink;
    }
    if ($(submitter).data('confirmScrollTo')) {
      const element = document.querySelector($(submitter).data('confirmScrollTo'))
      const block = $(submitter).data('confirmScrollToBlock') || 'start'

      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: block });
      }
    }
    let confirmationType = $(submitter).attr("data-confirm-type") || 'confirmation'
    if (confirmationType == 'delete') {
      const $dialog = $('#turbo_confirmation_delete');
      const originalHtml = $dialog.html();
      response = await new Promise((resolve) => {
        if ($(submitter).data('confirmButton')) {
          $dialog.find('[data-js=ok]').html($(submitter).data('confirmButton'));
        }
        if ($(submitter).data('turboConfirm')) {
          $dialog.find('[data-js=message]').html($(submitter).data('turboConfirm'));
        }
        if ($(submitter).data('confirmDetails')) {
          $dialog.find('[data-js=details]').html($(submitter).data('confirmDetails'));
        }
        else {
          $dialog.find('[data-js=details]').remove();
        }
        if ($(submitter).data('confirmHideFooter')) {
          $dialog.find('[data-js=footer]').remove();
        }
        $dialog.modal('show');
        let resolved = false;
        $dialog.on('hide.bs.modal', function () {
          resolve(resolved);
          $dialog.html(originalHtml);
          $dialog.off('hide.bs.modal');
          $dialog.off('click', '[data-js=ok]');
        })
        $dialog.on('click', '[data-js=ok]', function () {
          resolved = true;
          resolve(resolved);
          $dialog.modal('hide');
        })
      })
    }

    if (confirmationType == 'confirmation') {
      const $dialog = $('#turbo_confirmation');
      const originalHtml = $dialog.html();
      response = await new Promise((resolve) => {
        if ($(submitter).data('confirmButton')) {
          $dialog.find('[data-js=ok]').html($(submitter).data('confirmButton'));
        }
        if ($(submitter).data('turboConfirm')) {
          $dialog.find('[data-js=message]').html($(submitter).data('turboConfirm'));
        }
        if ($(submitter).data('confirmDetails')) {
          $dialog.find('[data-js=details]').html($(submitter).data('confirmDetails'));
        }
        else {
          $dialog.find('[data-js=details]').remove();
        }
        if ($(submitter).data('confirmHideFooter')) {
          $dialog.find('[data-js=footer]').remove();
        }
        $dialog.modal('show');
        let resolved = false;
        $dialog.on('hide.bs.modal', function () {
          resolve(resolved);
          $dialog.html(originalHtml);
          $dialog.off('hide.bs.modal');
          $dialog.off('click', '[data-js=ok]');
        })
        $dialog.on('click', '[data-js=ok]', function () {
          resolved = true;
          resolve(resolved);

          $dialog.modal('hide');
        })
      })
    }
  }

  if (response) {
    dispatch('confirm-accept', submitter);
  } else {
    dispatch('confirm-reject', submitter);
  }

  return response;
});
