import { Controller } from "@hotwired/stimulus"

function visible(target) {
  return !(target.hidden || target.classList.contains('hidden') || target.offsetParent === null)
}

export default class extends Controller {
  static targets = ['expandable', 'toggler']

  toggle({ params: { eventType } }) {
    if (this.hasTogglerTarget) {
      let action
      console.log(this.togglerTarget)
      if (this.togglerTarget.getAttribute('aria-expanded') === 'true') {
        action = 'collapse'
        this.togglerTarget.setAttribute('aria-expanded', false)
      } else {
        action = 'expand'
        this.togglerTarget.setAttribute('aria-expanded', true)
      }

      if (eventType) {
        this.expandableTargets.filter(visible).forEach((expandable) => {
          expandable.dispatchEvent(new CustomEvent(eventType, { bubbles: true, cancelable: false, detail: { action } }))
        })
      }
    }
  }
}
