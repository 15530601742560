import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    console.debug("Connect  modal!");
    $(this.element).modal('show');
    var self = this;
    $(this.element).on('hidden.bs.modal', function () {
      self.hide();
    })
  }

  hide() {
    $(this.element).off('hidden.bs.modal');
    $(this.element).modal('hide');
    this.element.remove();
  }
}
