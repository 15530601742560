import { Controller } from "@hotwired/stimulus";
import { post } from '@rails/request.js'

// Connects to data-controller="signposting-details"
export default class extends Controller {
  protected id: string | null = null;

  connect() {
    super.connect();
    this.id = $(this.element).data("id");
  }
  followUpRecordedHandler(e) {
    if (e.detail.signposting_id == this.id) {
      const data = e.detail;
      post(
        `/clients/${data.client_id}/update_signposting_details`,
        {
          body: JSON.stringify({
            add_follow_up: data
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
    }
  }
}
